export const tenders: TableModule = {
    name: "tenders",
    route: "spa.tenders",
    defaultOrder: {
        column: "visible_until",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortable: true,
            mobile: true,
            pageLink: true,
            breakpoint: "xl",
            width: "w-20"
        },
        {
            name: "user_id",
            sortable: true,
            breakpoint: "xl"
        },
        {
            name: "category_id",
            sortable: true,
            mobile: true,
            breakpoint: "xl"
        },
        {
            name: "positions",
        },
        {
            name: "tender_offer",
            width: "w-24"
        },
        {
            name: "visible_until",
            sortable: true,
            mobile: true,
            breakpoint: "xl",
            width: "w-40"
        },
        {
            name: "created_at",
            sortable: true,
            breakpoint: "xl",
            width: "w-40"
        }
    ],
}